import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×4\\@85%1RM`}</p>
    <p>{`Hamstring Curl 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-Alternating Step Ups Per Leg`}</p>
    <p>{`12-T2B`}</p>
    <p>{`14-KBS (53/35)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      